import React from "react";
import Footer from "../Shared/Footer";

const Checkout = () => {
    return (
        <div class="frame-ASD" style={{ background: "#2d0909" }}>
            <div className="container pb-5" >
                <div class="fw-row">
                    <div class="fw-col-xs-12 ">
                        <div class="fw-divider-space" style={{ marginTop: "50px" }}></div>
                    </div>
                </div>
                <div class="fw-row">
                    <div class="navbar-frame">
                        <a href="index.html"><img class="navbar-left-content" src="./assets/section-video/lifecode-logo.svg" /></a>
                        <div class="navbar-right-content" style={{ display: "flex" }}>
                            <div className="d-flex align-items-center">
                                <img class="icon-call-sms" src="./assets/section-video/call-calling.svg" />
                                {/* <p class="navbar-right-text mb-0"> +91 9829197777</p> */}
                                <a href="tel:+919829197777" class="navbar-right-text mb-0">+91 9829197777</a>

                            </div>

                            <div class="separator-1 vector-class"></div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img class="icon-call-sms vector-class" src="./assets/section-video/sms.svg" />
                                <p class="navbar-right-text vector-class mb-0">sales@lifecode.co.in</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="checkout d-flex justify-content-center mt-5">
                    <div className="checkout_card col-md-4 text-white p-4">
                        <h4 className="">Checkout</h4>
                        <div className="d-md-flex justify-content-between align-items-center mt-4">
                            <div className="product_detail">
                                <span>
                                    <img src="logo192.png" style={{ height: "60px", width: "60px" }} />
                                </span>
                                <span className="product_name" style={{ paddingLeft: "10px" }}>Life Code</span>
                            </div>
                            <span className="product_price">600Rs.</span>
                        </div>
                        <div className="paymeny_section">
                            <div className="coupon_input my-4 d-flex">
                                <input type="text" className="col-9" />
                                <button className="col-3">Apply</button>
                            </div>
                            <div>
                                <div className="payment_detail pb-4">
                                    <div className="d-flex justify-content-between">
                                        <span className="left_heading">Subtotal</span>
                                        <span className="right_text">600.00Rs</span>
                                    </div>
                                    <div className="d-flex justify-content-between mt-3">
                                        <span className="left_heading">Delivery & Handling</span>
                                        <span className="right_text">20.00Rs</span>
                                    </div>
                                    <div className="d-flex justify-content-between mt-3">
                                        <span className="left_heading">Discount</span>
                                        <span className="right_text">100.00Rs</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mt-3">
                                    <span className="total_amount">Total</span>
                                    <span className="total_amount">520.00Rs</span>
                                </div>
                                <div className="mt-4 place_order_btn">
                                    <button>Place Order</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Checkout;