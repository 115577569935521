import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route, HashRouter, Switch, Redirect } from "react-router-dom";
import Home from './components/Home';
import Register from './components/Register';
import Checkout from './components/Checkout';

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="checkout" element={<Checkout />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
