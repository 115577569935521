import React from "react";

const Footer = () =>{
    return(
        <section class="" style={{ backgroundColor: "#0cac09" }}>
        <div class="footer-cont">
            <div class="container">

                <div class="fw-row">
                    <div class="fw-col-xs-12 ">
                        <div class="fw-divider-space" style={{ marginTop: "100px" }}></div>
                    </div>
                </div>

                <div class="fw-row">
                    <div class="fw-col-xs-12 fw-col-sm-4 fw-col-md-4 ">
                        <img class="footer-logo" src="./assets/section-footer/lifefooter-logo.svg" />
                        <p class="footer-head-text ">Connect with us</p>
                        <div style={{ display: "flex" }}>
                            <img class="footer-sub-icon" src="./assets/section-footer/facebook-icon.svg" />
                            <img class="footer-sub-icon" src="./assets/section-footer/insta-icon.svg" />
                        </div>
                        <div class="fw-divider-space" style={{ marginTop: "60px" }}></div>
                    </div>
                    <div class="fw-col-xs-12 fw-col-sm-8 fw-col-md-8" style={{ display: "flex" }}>
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <p class="footer-head-text"> Links</p>
                            <a href="privacy-policy.html"> <p class="footer-sub-text">Privacy Policy</p></a>
                            <a href="terms-and-conditions.html"> <p class="footer-sub-text">Terms & Conditions</p></a>
                            <a href="faq.html"> <p class="footer-sub-text">FAQs</p></a>
                            <div class="fw-divider-space" style={{ marginTop: "60px" }}></div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <p class="footer-head-text">Contact Us</p>
                            <div class="icon-text-cont">
                                <img class="footer-sub-icon" src="./assets/section-footer/callingcall.svg" />
                                <p class="footer-sub-text">+91 9829197777</p>
                            </div>
                            <div class="icon-text-cont">
                                <img class="footer-sub-icon" src="./assets/section-footer/sms.svg" />
                                <p class="footer-sub-text">life@lifecode.co.in</p>
                            </div>
                            <div class="icon-text-cont">
                                <img class="footer-sub-icon" src="./assets/section-footer/location.svg" />
                                <p class="footer-sub-text">17, Civil Lines, Jaipur, Rajasthan-302006</p>
                            </div>
                            <div class="fw-divider-space" style={{ marginTop: "60px" }}></div>
                        </div>
                    </div>
                </div>

                <div class="fw-row">
                    <p class="footer-sub-text text-center">© 2024 — iShivax®. All Rights Reserved.
                    </p>
                </div>

                <div class="fw-row">
                    <div class="fw-col-xs-12 ">
                        <div class="fw-divider-space" style={{ marginTop: "60px" }}></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default Footer;