import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer";

const Home = () => {
    const [isOpen, setIsOpen] = useState(null);

    const handleClick = (index) => {
        setIsOpen(isOpen === index ? null : index);
    };

    const faqs = [
        {
            question: 'How to get QR code?', answer: `You don’t have to wait so long for your QR code to be deliver at your
                                                    address, It is now available in the shop, so you can directly buy it and
                                                    you can fill the details and you can generate your own QR code and paste
                                                    it on the vehicle.` },
        {
            question: 'How to Register?', answer: `After downloading the app you have to register by clicking on register
                                                    your QR option, and then you have to select the type and enter your
                                                    vehicle number.` },
        {
            question: 'How to setup Emergency number?', answer: `QR code will have 2 emergency numbers of the rider’s relatives. In case
                                                    of emergency, anyone can use this app to scan the bar code and inform
                                                    the injurer’s relatives.`}
    ];

    const targetRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    useEffect(() => {
        // Define the Intersection Observer callback
        function handleIntersection(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('show');
                    observer.unobserve(entry.target);
                }
            });
        }

        // Create an IntersectionObserver instance
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.1
        });

        // Observe each target element
        targetRefs.forEach(ref => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        // Cleanup function to unobserve elements on component unmount
        return () => {
            targetRefs.forEach(ref => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, []);

    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;
        let isScrolling = false;

        const handleScroll = () => {
            if (isScrolling) return;

            isScrolling = true;
            const scrollTop = container.scrollTop;
            const viewportHeight = window.innerHeight;

            const sections = document.querySelectorAll('.section');
            let nextSection = null;

            sections.forEach((section, index) => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.clientHeight;

                if (scrollTop + viewportHeight / 2 >= sectionTop + sectionHeight / 2) {
                    nextSection = sections[index + 1] || null;
                }
            });

            if (nextSection) {
                container.scrollTo({
                    top: nextSection.offsetTop,
                    behavior: 'smooth',
                });
            }

            // setTimeout(() => {
            //     isScrolling = false;
            // }, 500);
        };

        container.addEventListener('scroll', handleScroll);

        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div class="frame-ASD" ref={containerRef}>
            <section class="section">
                <div class="top-video-container">
                    <video
                        src={`${process.env.PUBLIC_URL}/assets/section-video/lifecodemobile-video.mp4`}
                        playsInline
                        autoPlay
                        muted
                        loop
                        className="top-video-section"
                    />
                    <div class="video-overlay-content">
                        <div class="container">


                            <div class="fw-row">
                                <div class="fw-col-xs-12 ">
                                    <div class="fw-divider-space" style={{ marginTop: "50px" }}></div>
                                </div>
                            </div>

                            <div class="fw-row">
                                <div class="navbar-frame">
                                    <a href="index.html"><img class="navbar-left-content" src="./assets/section-video/lifecode-logo.svg" /></a>
                                    <div class="navbar-right-content" style={{ display: "flex" }}>
                                        <div className="d-flex align-items-center">
                                            <img class="icon-call-sms" src="./assets/section-video/call-calling.svg" />
                                            {/* <p class="navbar-right-text mb-0"> +91 9829197777</p> */}
                                            <a href="tel:+919829197777" class="navbar-right-text mb-0">+91 9829197777</a>

                                        </div>

                                        <div class="separator-1 vector-class"></div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <img class="icon-call-sms vector-class" src="./assets/section-video/sms.svg" />
                                            <p class="navbar-right-text vector-class mb-0">sales@lifecode.co.in</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="fw-row">
                                <div class="fw-col-xs-12 ">
                                    <div class="fw-divider-space" style={{ marginTop: "15vh" }}></div>
                                </div>
                            </div>
                            <p class="overlay-text-simplifies"><b>SIMPLIFIES <br /> EMERGENCY</b></p>
                            <p class="overlay-text-available">Available On</p>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <a href="https://play.google.com/store/apps/details?id=com.ishivax.lifecode&pcampaignid=web_share">
                                    <img class="img-appstore" src="./assets/section-video/googleplay.svg"
                                        style={{ marginRight: "10px" }} />
                                </a>
                                <a href="https://apps.apple.com/in/app/lifecode-simplifies-emergency/id1546741259">
                                    <img class="img-appstore" src="./assets/section-video/appstore.svg" />
                                </a>
                            </div>
                            <div class="reviews-container">
                                <p class="text-ratings">4.8</p>
                                <div class="reviews-info">
                                    <img class="img-reviews" src="./assets/section-video/review-star.svg" />
                                    <p class="text-reviews">990+reviews</p>
                                </div>
                                <div class="separator-2"></div>
                                <div class="reviews-info">
                                    <img class="img-reviews" src="./assets/section-video/happclient.svg" />
                                    <p class="text-reviews">Happy Clients</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section" style={{ background: "#000" }}>
                <div class="fw-container red-image-section">
                    <div class="container">
                        <div class="fw-row">
                            <div class="fw-col-xs-12 ">
                                <div class="divider-space-red-1"></div>
                            </div>
                        </div>
                        <div class="fw-row">
                            <div class="fw-col-xs-12 fw-col-sm-6 fw-col-md-6 ">
                                <div class="sliding-text-content " ref={targetRefs[0]}>

                                    <p class="red-text-hope-you">HOPE YOU <span> NEVER <br />MEET AN </span>ACCIDENT</p>
                                    <p class="red-text-but-if">BUT IF IT DOES, LIFECODE WILL HELP AND ASSIST.</p>

                                </div>
                            </div>
                            <div class="fw-col-xs-12 fw-col-sm-6 fw-col-md-6 slider-col-red">
                                <div class="sliding-mobile-content" ref={targetRefs[1]}>
                                    <div style={{ display: "flex" }}>
                                        <img class="red-mobile-img" src="./assets/section-red/red-mobile.png"
                                            style={{ display: "block", margin: "auto" }} />
                                        <img class="red-dot-img" src="./assets/section-red/red-dot.svg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="fw-row">
                            <div class="content" style={{ display: "flex" }}>
                                <div class="fw-col-xs-12 fw-col-sm-5 fw-col-md-5 ">
                                </div>
                                <div class="fw-col-xs-12 fw-col-sm-3 fw-col-md-3 ">
                                    <div class="sliding-triangle-content" ref={targetRefs[2]}>
                                        <img class="red-triangle-img" src="./assets/section-red/red-triangle.png" />
                                    </div>
                                </div>
                                <div class="fw-col-xs-12 fw-col-sm-4 fw-col-md-4 ">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section">
                <div class="fw-container yellow-image-section">
                    <div class="container">
                        <div class="fw-row">
                            <div class="fw-col-xs-12 ">
                                <div class="divider-space-yellow-1"></div>
                            </div>
                        </div>
                        <div class="fw-row">
                            <div class="fw-col-xs-12 fw-col-sm-6 fw-col-md-6 ">
                                <div class="sliding-text-content-yellow " ref={targetRefs[3]}>

                                    <p class="yellow-text-how-our"><b>HOW OUR <br /><span>APPLICATION </span><br />WORKS?</b>
                                    </p>
                                    <div class="faq-container">
                                        {faqs?.map((item, i) => (
                                            <div class="faq-item">
                                                <div class="faq-question" onClick={() => handleClick(i)}>
                                                    <span>{item?.question}</span>
                                                    <button class="toggle-btn">{isOpen === i ? '-' : '+'}</button>
                                                </div>
                                                <div className={`faq-answer ${isOpen === i ? 'open' : ''}`}>
                                                    <p>{item?.answer}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div class="fw-col-xs-12 fw-col-sm-6 fw-col-md-6 slider-col-red">
                                <div class="sliding-qr-content" ref={targetRefs[4]}>
                                    <div style={{ display: "flex" }}>
                                        <img class="yellow-qr-img" src="./assets/section-yellow/yellow-scanqr.png" />

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <img class="yellow-layer-img sliding-layer-content-yellow" ref={targetRefs[5]}
                        src="./assets/section-yellow/Layer-yellow.png" alt="Description of the image" />

                </div>
            </section>

            <section class="section">
                <div class="fw-container green-image-section">
                    <div class="container" style={{ zIndex: "1" }}>
                        <div class="fw-row">
                            <div class="fw-col-xs-12 ">
                                <div class="divider-space-yellow-1"></div>
                            </div>
                        </div>
                        <div class="fw-row">
                            <div class="fw-col-xs-12">
                                <div class="sliding-text-content-green" ref={targetRefs[6]}>
                                    <p class="green-text-prod">OUR PRODUCTS</p>
                                    <div class="fw-divider-space" style={{ marginTop: "20px" }}></div>
                                    <p class="green-text-ultimate">ULTIMATE <span> SAFETY </span>COMPANION</p>
                                    <div class="fw-divider-space" style={{ marginTop: "40px" }}></div>
                                </div>
                            </div>
                        </div>
                        <div class="fw-row" style={{ position: "relative", zIndex: "1" }}>
                            <div class="sliding-products-content-green" ref={targetRefs[7]}>
                                <div class="green-products-frame">
                                    <div class="green-product-single">
                                        <img class="green-products-image" src="./assets/section-green/green-wheeler.png" />
                                        <p class="green-products-title">FOUR WHEELER</p>
                                        {/* https://www.amazon.in/dp/B0DF36ZM27 */}
                                        <div class="centered-frame-products">
                                            <a
                                                href="https://www.amazon.in/dp/B0DF36ZM27" style={{ textDecoration: "none" }}>
                                                <div class="green-button-frame" style={{ display: "flex" }}>

                                                    <div class="green-cart-svg"></div>
                                                    <p class="green-buy-now">Buy Now</p>
                                                </div>
                                            </a>

                                        </div>
                                    </div>
                                    <div class="green-product-single">
                                        <img class="green-products-image" src="./assets/section-green/green-pet.png" />
                                        <p class="green-products-title">PET</p>
                                        <div class="centered-frame-products">
                                            <a
                                                href="https://www.amazon.in/dp/B0DF39QJMX" style={{ textDecoration: "none" }}>
                                                <div class="green-button-frame" style={{ display: "flex" }}>
                                                    <div class="green-cart-svg"></div>
                                                    <p class="green-buy-now">Buy Now</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="green-product-single">
                                        <img class="green-products-image" src="./assets/section-green/green-twowheeler.png" />
                                        <p class="green-products-title">TWO WHEELER</p>
                                        <div class="centered-frame-products">
                                            <a
                                                href="https://www.amazon.in/dp/B0DF36YST2" style={{ textDecoration: "none" }}>
                                                <div class="green-button-frame" style={{ display: "flex" }}>
                                                    <div class="green-cart-svg"></div>
                                                    <p class="green-buy-now">Buy Now</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <img class="green-stack-img sliding-stack-content-green" ref={targetRefs[8]}
                        src="./assets/section-green/green-stack-image.png" alt="Description of the image" />

                    <img class="green-half-circle-img sliding-circle-content-green" ref={targetRefs[9]}
                        src="./assets/section-green/green-half-circle.png" alt="Description of the image" />

                    <img class="green-unfill-circle-img" src="./assets/section-green/green-unfill-circle.png" ref={targetRefs[10]}
                        alt="Description of the image" />
                </div>
            </section>

            <Footer />

        </div>
    )
}

export default Home;